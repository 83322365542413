import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import FmsTimesheetDetails from './fmsTimesheetDetails';
import { startOfMonth, endOfMonth, getWeeksInMonth } from 'date-fns';
import { ModelSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
import { required } from 'vuelidate/lib/validators';
import uploadExcel from '@/app/components/shared/uploadExcel';
export default {
  name: 'FmsTimesheet',
  components: {
    DatePicker,
    ModelSelect,
    FmsTimesheetDetails,
    uploadExcel
  },
  watch: {
    currentPage: function() {
      this.getFmsTimesheetList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getFmsTimesheetList();
    }
  },
  data() {
    return {
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      unsubscribe: null,
      parent_value_set_id: null,
      depVsetParam: null,
      showAddTimesheetModal: false,
      showValueSetModal: false,
      loader: false,
      isSuccess: false,
      responseMsg: '',
      showAlert: false,
      editMode: false,
      showUploadExcel: false,
      project: {
        id: null,
        text: null
      },
      salesAgreement: {
        id: null,
        text: null
      },
      billingPlan: {
        id: null,
        text: null
      },
      salesAgreementComp: {
        id: null,
        text: null
      },
      salesAgreementCompDtl: {
        id: null,
        text: null
      },
      employeeName: null,
      month: null,
      timesheetData: [],
      timesheetFields: [],
      weeks: [],
      firstDayOfTheWeek: null,
      lastDayOfTheWeek: null,
      timesheetId: null,
      initialTimesheetData: [],
      addMode: false,
      payload: null
    };
  },
  validations: {
    project: {
      text: { required }
    },
    salesAgreement: {
      text: { required }
    },
    month: { required }
  },
  mounted() {
    this.customMonthFunction();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showAddTimesheetModal = true;
          this.addMode = true;
        }
        if (actionName === 'download') {
          this.$v.$touch();
          if (!this.$v.$invalid) {
            this.loader = true;
            this.getDownloadTotalList();
          } else {
            alert('Please select all the mandatory fields');
          }
        }
        if (actionName === 'update') {
          this.editMode = !this.editMode;
        }
        if (actionName === 'upload') {
          this.showUploadExcel = true;
        }
        if (actionName === 'delete') {
          this.deleteFmsTimesheet();
        }
        if (
          actionName === 'save' &&
          !this.addMode &&
          this.initialTimesheetData.length > 0
        ) {
          this.addEditTimesheetDetails();
        }
      }
    });
  },
  methods: {
    getFmsTimesheetList() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.payload = {
          _limit: this.perPage,
          _page: this.currentPage - 1,
          prj_id: this.project.id,
          sales_agreement_hdr_id: this.salesAgreement.id,
          sales_agreement_comp_id: this.salesAgreementComp.id,
          sales_agreement_comp_dtl_id: this.salesAgreementCompDtl.id,
          bill_plan_hdr_id: this.billingPlan.id,
          employee_name: this.employeeName,
          start_date: this.firstDayOfTheWeek,
          end_date: this.lastDayOfTheWeek
        };
        this.loader = true;
        this.$store
          .dispatch('fmsTimesheet/getFmsTimesheetList', this.payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.timesheetData = response.data.data.page;
              this.initialTimesheetData = JSON.parse(
                JSON.stringify(response.data.data.page)
              );
              this.addMode = false;
              this.totalRows = response.data.data.total_elements;
            }
          })
          .catch(err => {
            this.err = err;
            this.loader = false;
          });
      }
    },
    addEditTimesheetDetails() {
      const timesheetPayloadData = [];
      const InitialPayloadData = [];
      const timesheetArr = [];
      this.initialTimesheetData.map(item =>
        InitialPayloadData.push(item.timesheet)
      );
      this.timesheetData.map(item => timesheetPayloadData.push(item.timesheet));
      for (let i = 0; i < timesheetPayloadData.length; i++) {
        timesheetPayloadData[i].map(item => {
          const tempItem = InitialPayloadData[i].find(
            temp => temp.timesheet_id === item.timesheet_id
          );
          if (
            tempItem &&
            (tempItem.hours !== item.hours ||
              tempItem.shift_name !== item.shift_name ||
              tempItem.overtime_hour !== item.overtime_hour ||
              tempItem.remarks !== item.remarks)
          ) {
            timesheetArr.push({
              workday_shift_id: item.workday_shift_id,
              timesheet_id: item.timesheet_id,
              attendance: item.hours === 0 ? 'A' : item.attendence,
              hours: item.hours,
              overtime_hour: item.overtime_hour,
              remarks: item.remarks
            });
          }
        });
      }
      const payload = {
        timesheets: timesheetArr
      };
      this.$store
        .dispatch('fmsTimesheet/addEditTimesheetDetails', payload)
        .then(response => {
          if (response.status === 200) {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.editMode = false;
            this.responseMsg = response.data.message;
            this.getFmsTimesheetList();
          } else {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(err => {
          this.err = err;
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    getDownloadTotalList() {
      this.payload = {
        _limit: this.perPage,
        _page: this.currentPage - 1,
        sales_agreement_hdr_id: this.salesAgreement.id,
        sales_agreement_comp_id: this.salesAgreementComp.id,
        sales_agreement_comp_dtl_id: this.salesAgreementCompDtl.id,
        start_date: this.firstDayOfTheWeek,
        end_date: commonHelper.formattedDate(endOfMonth(this.month))
      };
      this.loader = true;
      this.$store
        .dispatch('fmsTimesheet/downloadTimesheetList', this.payload)
        .then(response => {
          if (response.status === 200) {
            const totalElement = response.data.data.total_elements;
            const downloadPayload = {
              _limit: totalElement,
              _page: this.currentPage - 1,
              sales_agreement_hdr_id: this.salesAgreement.id,
              sales_agreement_comp_id: this.salesAgreementComp.id,
              sales_agreement_comp_dtl_id: this.salesAgreementCompDtl.id,
              bill_plan_hdr_id: this.billingPlan.id,
              start_date: this.firstDayOfTheWeek,
              end_date: commonHelper.formattedDate(endOfMonth(this.month))
            };
            this.downloadExcel.downloadData(
              downloadPayload,
              'fmsTimesheet/downloadTimesheetList',
              'fms-timeSheet',
              () => (this.loader = false)
            );
          }
        })
        .catch(err => {
          this.err = err;
          this.loader = false;
        });
    },
    deleteFmsTimesheet() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const toDelete = confirm('Are you sure you want to delete Timesheet.');
        if (toDelete) {
          const payload = {
            sales_agreement_hdr_id: this.salesAgreement.id,
            sales_agreement_comp_id: this.salesAgreementComp.id,
            sales_agreement_comp_dtl_id: this.salesAgreementCompDtl.id,
            bill_plan_hdr_id: this.billingPlan.id,
            start_date: commonHelper.formattedDate(startOfMonth(this.month)),
            end_date: commonHelper.formattedDate(endOfMonth(this.month))
          };
          this.$store
            .dispatch('fmsTimesheet/deleteFmsTimesheet', payload)
            .then(response => {
              if (response.status === 200) {
                this.loader = false;
                this.showAlert = true;
                this.isSuccess = true;
                this.editMode = false;
                this.responseMsg = response.data.message;
                this.getFmsTimesheetList();
              } else {
                this.loader = false;
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg = response.response.data.message;
              }
            })
            .catch(err => {
              this.err = err;
              this.loader = false;
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = appStrings.autoFailedMsg;
            });
        }
      } else {
        alert('Please Select mandatory fields first.');
      }
    },
    selectedTab(index) {
      this.customMonthFunction();
      this.weekSelectionFunction(index);
      this.timesheetFields = this.timesheetFields.slice(
        index * 7,
        index * 7 + 7
      );
    },
    customMonthFunction() {
      this.timesheetData = [];
      this.totalRows = null;
      const firstDay = startOfMonth(new Date(this.month))
        .toString()
        .split(' ')[2];
      const lastDay = endOfMonth(new Date(this.month))
        .toString()
        .split(' ')[2];
      const daysOfTheMonth = [];
      for (let i = firstDay; i <= lastDay; i++) {
        let date =
          i +
          '-' +
          startOfMonth(new Date(this.month))
            .toString()
            .split(' ')[1] +
          '-' +
          startOfMonth(new Date(this.month))
            .toString()
            .split(' ')[3];
        let day = new Date(date).toString().split(' ')[0];
        daysOfTheMonth.push({ key: date, day: day });
      }
      this.timesheetFields = [...daysOfTheMonth];
    },
    weekSelectionFunction(index) {
      if (!this.month) {
        this.timesheetData = [];
        this.totalRows = null;
      }
      if (this.month) {
        const firstDayOfTheMonth = commonHelper.formattedDate(
          startOfMonth(this.month)
        );
        const lastDayOfTheMonth = commonHelper.formattedDate(
          endOfMonth(this.month)
        );
        if (index === 1) {
          this.firstDayOfTheWeek = '08' + firstDayOfTheMonth.slice(2);
          this.lastDayOfTheWeek = '14' + lastDayOfTheMonth.slice(2);
        } else if (index === 2) {
          this.firstDayOfTheWeek = '15' + firstDayOfTheMonth.slice(2);
          this.lastDayOfTheWeek = '21' + lastDayOfTheMonth.slice(2);
        } else if (index === 3) {
          this.firstDayOfTheWeek = '22' + firstDayOfTheMonth.slice(2);
          this.lastDayOfTheWeek = '28' + lastDayOfTheMonth.slice(2);
        } else if (index === 4) {
          this.firstDayOfTheWeek = '29' + firstDayOfTheMonth.slice(2);
          this.lastDayOfTheWeek = lastDayOfTheMonth;
        } else {
          this.firstDayOfTheWeek = firstDayOfTheMonth;
          this.lastDayOfTheWeek = '07' + lastDayOfTheMonth.slice(2);
        }
        if (index === 0 || index) {
          this.getFmsTimesheetList();
        }
      }
    },
    customWeekFunction() {
      this.customMonthFunction();
      this.weekSelectionFunction();
      this.timesheetFields = this.timesheetFields.slice(0, 7);
      this.weeks = [];
      const noOfWeeksInThisMonth = getWeeksInMonth(this.month);
      for (let i = 1; i <= noOfWeeksInThisMonth; i++) {
        if (i <= 5) {
          this.weeks.push({ key: 'Week ' + i });
        }
      }
    },
    openValueSetModal(vsetCode, item) {
      if (item) {
        this.timesheetId = item.timesheet_id;
      }
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_BY_USER_ID) {
        this.parent_value_set_id = this.$store.state.auth.userId;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_SALES_AGREEMENT_BY_USER
      ) {
        this.parent_value_set_id = this.project.id;
        this.depVsetParam = this.$store.state.auth.userId;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.BILLING_PLAN_NAME_BY_TIMESHEET
      ) {
        this.parent_value_set_id = this.salesAgreement.id;
      } else if (
        this.vsetCode ===
        appStrings.VALUESETTYPE.SALES_AGREEMENT_COMP_BY_TIMESHEET
      ) {
        this.parent_value_set_id = this.salesAgreement.id;
        this.depVsetParam = this.billingPlan.id;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.SALES_AGREEMENT_COMP_DTL
      ) {
        this.parent_value_set_id = this.salesAgreementComp.id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.SHIFT_NAME) {
        this.parent_value_set_id = item.workday_hdr_id;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_BY_USER_ID) {
        this.project = {
          id: item.value_set_dtl_id,
          text: item.value_code
        };
        this.salesAgreement = { id: null, text: null };
        this.billingPlan = { id: null, text: null };
        this.salesAgreementComp = { id: null, text: null };
        this.salesAgreementCompDtl = { id: null, text: null };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_SALES_AGREEMENT_BY_USER
      ) {
        this.salesAgreement = {
          id: item.value_set_dtl_id,
          text: item.value_code
        };
        this.billingPlan = { id: null, text: null };
        this.salesAgreementComp = { id: null, text: null };
        this.salesAgreementCompDtl = { id: null, text: null };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.BILLING_PLAN_NAME_BY_TIMESHEET
      ) {
        this.billingPlan = {
          id: item.value_set_dtl_id,
          text: item.value_code
        };
        this.salesAgreementComp = { id: null, text: null };
      } else if (
        this.vsetCode ===
        appStrings.VALUESETTYPE.SALES_AGREEMENT_COMP_BY_TIMESHEET
      ) {
        this.salesAgreementComp = {
          id: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.SALES_AGREEMENT_COMP_DTL
      ) {
        this.salesAgreementCompDtl = {
          id: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.SHIFT_NAME) {
        for (let i = 0; i < this.timesheetData.length; i++) {
          for (let j = 0; j < this.timesheetData[i].timesheet.length; j++) {
            if (
              this.timesheetData[i].timesheet[j].timesheet_id ===
              this.timesheetId
            ) {
              this.timesheetData[i].timesheet[j].shift_name = item.shift_name;
              this.timesheetData[i].timesheet[j].workday_shift_id =
                item.workday_shift_id;
              break;
            }
          }
        }
      }
    },
    clearVsetValues(vsetCode, item) {
      if (item) {
        this.timesheetId = item.timesheet_id;
      }
      this.vsetCode = vsetCode;
      if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_BY_USER_ID) {
        this.project = { id: null, text: null };
        this.salesAgreement = { id: null, text: null };
        this.billingPlan = { id: null, text: null };
        this.salesAgreementComp = { id: null, text: null };
        this.salesAgreementCompDtl = { id: null, text: null };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_SALES_AGREEMENT_BY_USER
      ) {
        this.salesAgreement = { id: null, text: null };
        this.billingPlan = { id: null, text: null };
        this.salesAgreementComp = { id: null, text: null };
        this.salesAgreementCompDtl = { id: null, text: null };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.BILLING_PLAN_NAME_BY_TIMESHEET
      ) {
        this.billingPlan = { id: null, text: null };
        this.salesAgreementComp = { id: null, text: null };
      } else if (
        this.vsetCode ===
        appStrings.VALUESETTYPE.SALES_AGREEMENT_COMP_BY_TIMESHEET
      ) {
        this.salesAgreementComp = { id: null, text: null };
        this.salesAgreementCompDtl = { id: null, text: null };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.SALES_AGREEMENT_COMP_DTL
      ) {
        this.salesAgreementCompDtl = { id: null, text: null };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.SHIFT_NAME) {
        for (let i = 0; i < this.timesheetData.length; i++) {
          for (let j = 0; j < this.timesheetData[i].timesheet.length; j++) {
            if (
              this.timesheetData[i].timesheet[j].timesheet_id ===
              this.timesheetId
            ) {
              this.timesheetData[i].timesheet[j].shift_name = null;
              break;
            }
          }
        }
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearFilters() {
      this.timesheetData = [];
      this.totalRows = null;
      this.project = { id: null, text: null };
      this.billingPlan = { id: null, text: null };
      this.salesAgreement = { id: null, text: null };
      this.salesAgreementComp = { id: null, text: null };
      this.salesAgreementCompDtl = { id: null, text: null };
      this.employeeName = null;
      this.month = null;
    },
    hideAddTimesheetModal() {
      this.showAddTimesheetModal = false;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
