import { ModelSelect } from 'vue-search-select';
import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
import { startOfMonth, endOfMonth } from 'date-fns';
import URL_UTILITY from '../../../../utility/url.utility';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'FmsTimesheetDetails',
  components: {
    ModelSelect,
    DatePicker
  },
  data() {
    return {
      unsubscribe: null,
      showValueSetModal: false,
      downloadLog: URL_UTILITY.getDownloadLOGUrl,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      editMode: false,
      parent_value_set_id: null,
      depVsetParam:null,
      project: {
        id: null,
        text: null
      },
      salesAgreement: {
        id: null,
        text: null
      },
      defaultValue: {
        id: null,
        text: null,
      },
      month:null,
      firstDayOfTheMonth:null,
      lastDayOfTheMonth:null,
      templateId:null,
      requestId:null,
      requestNumId:0
    };
  },
  validations: {
    project: {
      text: { required }
    },
    salesAgreement: {
      text: { required }
    },
    month: { required }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          this.addEditTimesheet();
        }
      }
    });
  },
  methods: {
    customWeekFunction(){
      if(this.month){
        this.firstDayOfTheMonth = commonHelper.formattedDate(
          startOfMonth(this.month)
        );
        this.lastDayOfTheMonth = commonHelper.formattedDate(
          endOfMonth(this.month)
        );
        this.checkFmsTimesheet();
      }
    },
    checkFmsTimesheet() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          sales_agreement_hdr_id: this.salesAgreement.id,
          start_date: this.firstDayOfTheMonth,
          end_date: this.lastDayOfTheMonth
        };
        this.loader = true;
        this.$store
          .dispatch('fmsTimesheet/checkFmsTimesheet', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              if(response.data.data){
                this.loader = false;
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg = response.data.data;
              }
            }
          })
          .catch(err => {
            this.err = err;
            this.loader = false;
          });
        }
    },
    addEditTimesheet() {
      const data = {
        sales_agreement_hdr_id: this.salesAgreement.id,
        start_date:this.firstDayOfTheMonth, 
        end_date:this.lastDayOfTheMonth ,
      }
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          _page:0,
          _limit:10,
          template_name: 'GENERATE_TIMESHEET',
        }
        this.$store
          .dispatch('template/getTemplateList', payload)
          .then(response => {
            if (response.status === 200) {
              this.loader = false;
              this.showAlert = true;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              this.requestId=response.data.data.page[0].request_id;
              this.templateId=response.data.data.page[0].template_id;
              this.saveSubmitRequest(data);
            } else {
              this.loader = false;
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(err => {
            this.err = err;
            this.loader = false;
          });
  
      }
    },
    saveSubmitRequest(data) {
      const payload = {
        admin_submit_req_details: [
          {
            request_num: 0,
            request_id: this.requestId,
            template_id: this.templateId,
            schedule_type: 'asap', //pass hardcode
            request_start_date: null,
            request_end_date: null,
            resubmit_interval: null,
            resubmit_interval_unit: null,
            day_of_month: null,
            day_of_week: null,
            output_format_id: 'BLANK', //pass hardcode
            no_of_args: 3,
            request_parameter: JSON.stringify(data),
            sms_flag: false,
            email_flag: false,
            whatsapp_flag: false,
            child_req_count: null,
            email_comm_template_id: null,
            sms_comm_template_id: null,
            whatsapp_comm_template_id: null,
            argument1: JSON.stringify(data),
            argument2: null,
            argument3: null,
            argument4: null,
            argument5: null,
            argument6: null,
            argument7: null,
            argument8: null,
            argument9: null,
            argument10: null,
            argument11: null,
            argument12: null,
            argument13: null,
            argument14: null,
            argument15: null,
            argument16: null,
            argument17: null,
            argument18: null,
            argument19: null,
            argument20: null,
            argument21: null,
            argument22: null,
            argument23: null,
            argument24: null,
            argument25: null
          }
        ]
      };
      this.loader = true;
      this.$store
        .dispatch('template/saveSubmitRequest', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 201) {
            this.requestNumId = resp.data.data[0].id;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_BY_USER_ID) {
        this.parent_value_set_id = this.$store.state.auth.userId;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_SALES_AGREEMENT_BY_USER
      ) {
        this.parent_value_set_id = this.project.id;
        this.depVsetParam = this.$store.state.auth.userId;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
     if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_BY_USER_ID) {
        this.project = {
          text: item.value_code,
          id: item.value_set_dtl_id
        };
        this.salesAgreement = this.defaultValue;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_SALES_AGREEMENT_BY_USER) {
        this.salesAgreement = {
          id: item.value_set_dtl_id,
          text: item.value_code
        };
      }
    },
    clearVsetValues(vsetCode) {
      this.vsetCode = vsetCode;
      if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_BY_USER_ID) {
        this.project = this.defaultValue;
        this.salesAgreement = this.defaultValue;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_SALES_AGREEMENT_BY_USER) {
        this.salesAgreement = this.defaultValue;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
